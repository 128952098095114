<template>
  <div :class="checkboxContainerClasses">
    <label
      class="flex gap-3"
      :class="[
        labelClasses,
        flexAlignmentClass,
        { 'flex-row-reverse': flip, 'p-3 border border-ink': block }
      ]">
      <input
        :id="inputId"
        class="text-paper-2"
        :data-cy="inputId"
        :class="[checkboxInputClasses, { toggle: toggle }]"
        :checked="checked"
        type="checkbox"
        @change="$emit('update:checked', $event.target.checked)" />
      <div v-if="label">
        <span v-html="label"></span>
        <span v-if="required">*</span>
        <slot name="description"></slot>
      </div>
    </label>
    <p
      v-if="hasErrors"
      class="error-box mt-2">
      {{ inputErrors.join(", ") }}
    </p>
  </div>
</template>

<script>
export default {
  name: "CheckboxInput",
  props: {
    checked: {
      type: Boolean,
      required: true
    },
    label: {
      type: String,
      default: ""
    },
    required: {
      type: Boolean,
      default: false
    },
    inputId: {
      type: String,
      required: true,
      default: ""
    },
    errors: {
      type: Array,
      default: () => []
    },
    block: {
      type: Boolean,
      default: false
    },
    newLineError: {
      type: Boolean,
      default: false
    },
    flexAlignmentClass: {
      type: String,
      default: "items-center"
    },
    labelClasses: {
      type: String,
      default: ""
    },
    filtersCheckbox: {
      type: Boolean,
      default: false
    },
    toggle: {
      type: Boolean,
      default: false
    },
    flip: {
      type: Boolean,
      default: false
    }
  },
  emits: ["update:checked"],
  computed: {
    inputErrors() {
      return this.errors || [];
    },
    hasErrors() {
      return this.inputErrors.length > 0;
    },
    checkboxContainerClasses() {
      if (this.hasErrors) {
        return "has-errors";
      }
      if (this.filtersCheckbox) {
        return "filter-checkbox";
      }
      return "";
    },
    checkboxInputClasses() {
      if (this.filtersCheckbox) {
        return "h-5 w-5 cursor-pointer filter-checkbox-input";
      }
      if (this.flexAlignmentClass) {
        return "items-start";
      }
      return "";
    }
  }
};
</script>
